<template>
    <span>-- {{ boxShow }}, {{ showBox }}</span>
    <div v-if="boxShow === true" class="position-fixed bottom-0 end-0 py-0 px-1 vh-100 notificationFloat">
        <div class="notificationInnerBox" :style="'height: ' +notifHeight+ 'px'">
            <!-- Title -->
            <div>
                <span class="align-middle fw-bold">NOTIFICATION</span>
                
                <span class="float-end pe-1">
                    <span v-if="notifLastPage !== 0">
                        <span v-if="notifPage === 1" class="px-2">
                            <fa icon="angle-left" />
                        </span>
                        <span v-else class="px-2 isLink" @click="navigateNotif('prev')">
                            <fa icon="angle-left" />
                        </span>
                    </span>
                    <span v-if="notifLastPage !== 0" class="fw-normal">{{ notifPage }} / {{ notifLastPage }}</span>
                    <span v-if="notifLastPage !== 0">
                        <span v-if="notifPage >= notifLastPage" class="px-2">
                            <fa icon="angle-right" />
                        </span>
                        <span v-else class="px-2 isLink" @click="navigateNotif('next')">
                            <fa icon="angle-right" />
                        </span>
                    </span>

                    <div class="btn-group btn-group-sm pe-0 me-0">
                        <button type="button" class="btn btn-sm bg-white isLink mt-0 pt-0" data-bs-toggle="dropdown" id="dropdownMenuButton1" aria-expanded="false" style="border-top-right-radius: 0.2rem; border-bottom-right-radius: 0.2rem">
                            <fa icon="ellipsis-vertical"/>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <span class="dropdown-item" @click="notifFilter = ''" style="font-size: 0.875rem">
                                    Show all notification
                                </span>
                            </li>
                            <li>
                                <span class="dropdown-item" @click="notifFilter = 'unread'" style="font-size: 0.875rem">
                                    Show unread only
                                </span>
                            </li>
                            <li>
                                <span class="dropdown-item" @click="notifFilter = 'read'" style="font-size: 0.875rem">
                                    Show read only
                                </span>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <li>
                                <span class="dropdown-item" data-bs-toggle="modal" data-bs-target="#mdlDelAllNotif" style="font-size: 0.875rem">
                                    Delete all notification
                                </span>
                            </li>
                        </ul>
                    </div>
                </span>
            </div>

            <!-- Content -->
            <div>
                <table class="table table-hover" style="margin-bottom: 0px; padding-bottom: 0px;">
                    <tbody>
                        <tr v-for="(n, nIdx) in arrNotif" :key="n">
                            <td>
                                <div class="mb-1">
                                    <!-- Read: notification -->
                                    <table v-if="n.isRead === true || n.IsRead === 'true'" width="100%" border="0">
                                        <tr>
                                            <td>
                                                <span v-if="n.action === 'FOLDER_CREATED'">Folder added</span>
                                                <span v-if="n.action === 'FOLDER_SEALED'">Folder sealed</span>
                                                <span v-if="n.action === 'FOLDER_SHREDDED' || n.action === 'FOLDER_SHRED_ALLAPPROVED'">Folder shredded</span>
                                                <span v-if="n.action === 'FOLDER_SHRED_APPROVED'">Folder shred approved</span>
                                                <span v-if="n.action === 'FOLDER_SHRED_CANCELREJECTED'">Undo rejection of folder shred</span>
                                                <span v-if="n.action === 'FOLDER_SHRED_STAGING'">Folder pending shred approval</span>
                                                <span v-if="n.action === 'FOLDER_SHRED_REJECTED'">Folder shred rejected</span>
                                                <span v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === 'SEALED'">Folder unsealed</span>
                                                <span v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === 'CIRCULATING'">Folder paused</span>
                                                
                                                <span v-if="n.action === 'DOCUMENT_ALLSIGNED'">Document signed by all</span>
                                                <span v-if="n.action === 'DOCUMENT_APPROVED'">Document approved</span>
                                                <span v-if="n.action === 'DOCUMENT_CANCELREJECTED'">Undo rejection to sign document</span>
                                                <span v-if="n.action === 'DOCUMENT_REJECTED'">Document rejected</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED'">Document shred request</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_ALLAPPROVED' || n.action === 'DOCUMENT_SHREDDED'">Document shredded</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_APPROVED'">Document shred approved</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_CANCELREJECTED'">Undo rejection of document shred</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_PENDING'">Document pending shred approval</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_REJECTED'">Document shred rejected</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_STAGING'">Document marked as shred</span>
                                                <span v-if="n.action === 'DOCUMENT_SIGNED'">Document signed</span>
                                                <span v-if="n.action === 'DOCUMENT_UPLOADED'">Document uploaded</span>

                                                <!-- Cater old format -->
                                                <span v-if="n.action === 'REVIEW FOLDER'">Review folder</span>
                                                <span v-if="n.action === 'FOLDER_EMPTY'">Empty folder</span>
                                                <span v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === undefined">Folder paused</span>
                                            </td>
                                            <td class="align-top" width="20px">
                                                <span class="float-end" @click.prevent="delNotification(n.id)"><fa icon="trash" class="isLink" /></span>
                                            </td>
                                        </tr>
                                    </table>
                                    <!-- Unread: notification -->
                                    <table v-else width="100%" border="0">
                                        <tr>
                                            <td class="fw-bold isLink" @click="readNotification(n.id, n.isRead)">
                                                <span v-if="n.action === 'FOLDER_CREATED'">Folder added</span>
                                                <span v-if="n.action === 'FOLDER_SEALED'">Folder sealed</span>
                                                <span v-if="n.action === 'FOLDER_SHREDDED' || n.action === 'FOLDER_SHRED_ALLAPPROVED'">Folder shredded</span>
                                                <span v-if="n.action === 'FOLDER_SHRED_APPROVED'">Folder shred approved</span>
                                                <span v-if="n.action === 'FOLDER_SHRED_CANCELREJECTED'">Undo rejection of folder shred</span>
                                                <span v-if="n.action === 'FOLDER_SHRED_STAGING'">Folder pending shred approval</span>
                                                <span v-if="n.action === 'FOLDER_SHRED_REJECTED'">Folder shred rejected</span>
                                                <span v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === 'SEALED'">Folder unsealed</span>
                                                <span v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === 'CIRCULATING'">Folder paused</span>
                                                
                                                <span v-if="n.action === 'DOCUMENT_ALLSIGNED'">Document signed by all</span>
                                                <span v-if="n.action === 'DOCUMENT_APPROVED'">Document approved</span>
                                                <span v-if="n.action === 'DOCUMENT_CANCELREJECTED'">Undo rejection to sign document</span>
                                                <span v-if="n.action === 'DOCUMENT_REJECTED'">Document rejected</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED'">Document shred request</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_ALLAPPROVED' || n.action === 'DOCUMENT_SHREDDED'">Document shredded</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_APPROVED'">Document shred approved</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_CANCELREJECTED'">Undo rejection of document shred</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_PENDING'">Document pending shred approval</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_REJECTED'">Document shred rejected</span>
                                                <span v-if="n.action === 'DOCUMENT_SHRED_STAGING'">Document marked as shred</span>
                                                <span v-if="n.action === 'DOCUMENT_SIGNED'">Document signed</span>
                                                <span v-if="n.action === 'DOCUMENT_UPLOADED'">Document uploaded</span>

                                                <!-- Cater old format -->
                                                <span v-if="n.action === 'REVIEW FOLDER'">Review folder</span>
                                                <span v-if="n.action === 'FOLDER_EMPTY'">Empty folder</span>
                                                <span v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === undefined">Folder paused</span>
                                                
                                            </td>
                                            <td class="align-top" width="20px">
                                                <span class="float-end" @click.prevent="delNotification(n.id)"><fa icon="trash" class="isLink" /></span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div v-if="func.convDateTimeFormat(n.dateCreate, 'datetime') > '2024-10-16 16:32'" class="small fw-light">
                                    <div v-if="n.isRead === true || n.IsRead === 'true'">
                                        <!-- New format (with jRemark) -->
                                        <div v-if="n.action === 'FOLDER_CREATED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has created folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SEALED'">
                                            Folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span> is sealed since all signees signed.
                                        </div>
                                        <!-- FOLDER_SHREDDED & FOLDER_SHRED_ALLAPPROVED only exists either one -->
                                        <div v-if="n.action === 'FOLDER_SHREDDED'">
                                            Folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_ALLAPPROVED'">
                                            Folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> after get approval from all signees.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_APPROVED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has approved the shredding of folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_CANCELREJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has canceled the declined shredding of folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_STAGING'">
                                            Folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span> is awaiting approval from the signee(s) for shredding.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_REJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has declined the shredding of folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === 'SEALED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has unsealed folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span> for revision.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === 'CIRCULATING'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has paused the distribution of folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        
                                        <div v-if="n.action === 'DOCUMENT_ALLSIGNED'">
                                            Document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> has been signed by all signees.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_APPROVED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has approved document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_CANCELREJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has canceled the declined to sign document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_REJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has declined to sign document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has requested the shredding of document <span class="text-bold">{{ n.jRemark.documentName }}</span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_ALLAPPROVED'">
                                            Document <span class="text-bold">{{ n.jRemark.documentName }}</span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> after get approval from all signees.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_STAGING'">
                                            Document <span class="text-bold">{{ n.jRemark.documentName }}</span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is marked as shred by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper>.
                                        </div>

                                        <div v-if="n.action === 'DOCUMENT_SHREDDED'">
                                            Document <span class="text-bold">{{ n.jRemark.documentName }}</span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_APPROVED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has approved the shredding of document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_CALCELREJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has canceled the declined shredding of document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_PENDING'">
                                            Document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is awaiting approval from the signee(s) for shredding.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_REJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has declined the shredding of document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SIGNED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has signed document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_UPLOADED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has uploaded document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> into folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                    </div>
                                    <div v-else @click="readNotification(n.id, n.isRead)">
                                        <!-- New format (with jRemark) -->
                                        <div v-if="n.action === 'FOLDER_CREATED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has created folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SEALED'">
                                            Folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span> is sealed since all signees signed.
                                        </div>
                                        <!-- FOLDER_SHREDDED & FOLDER_SHRED_ALLAPPROVED only exists either one -->
                                        <div v-if="n.action === 'FOLDER_SHREDDED'">
                                            Folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_ALLAPPROVED'">
                                            Folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> after get approval from all signees.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_APPROVED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has approved the shredding of folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_CANCELREJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has canceled the declined shredding of folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_STAGING'">
                                            Folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span> is awaiting approval from the signee(s) for shredding.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_SHRED_REJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has declined the shredding of folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === 'SEALED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has unsealed folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span> for revision.
                                        </div>
                                        <div v-if="n.action === 'FOLDER_TO_WIP' && n.jRemark.folderOldStatus === 'CIRCULATING'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has paused the distribution of folder <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId">{{ n.jRemark.folderName }}</router-link></span>.
                                        </div>
                                        
                                        <div v-if="n.action === 'DOCUMENT_ALLSIGNED'">
                                            Document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> has been signed by all signees.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_APPROVED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has approved document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_CANCELREJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has canceled the declined to sign document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_REJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has declined to sign document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has requested the shredding of document <span class="text-bold">{{ n.jRemark.documentName }}</span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_ALLAPPROVED'">
                                            Document <span class="text-bold">{{ n.jRemark.documentName }}</span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> after get approval from all signees.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_STAGING'">
                                            Document <span class="text-bold">{{ n.jRemark.documentName }}</span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is marked as shred by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper>.
                                        </div>

                                        <div v-if="n.action === 'DOCUMENT_SHREDDED'">
                                            Document <span class="text-bold">{{ n.jRemark.documentName }}</span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_APPROVED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has approved the shredding of document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_CALCELREJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has canceled the declined shredding of document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_PENDING'">
                                            Document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span> is awaiting approval from the signee(s) for shredding.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SHRED_REJECTED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has declined the shredding of document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_SIGNED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has signed document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> in folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                        <div v-if="n.action === 'DOCUMENT_UPLOADED'">
                                            <Popper class="popperDark" arrow hover :content="n.jRemark.userId"><span class="isTooltipsLabel">{{ n.jRemark.displayName }}</span></Popper> has uploaded document <span class="text-bold"><router-link :to="'/p/' +n.jRemark.folderId+ '/' +n.jRemark.documentId">{{ n.jRemark.documentName }}</router-link></span> into folder <span class="text-bold">{{ n.jRemark.folderName }}</span>.
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <!-- old format -->
                                    <div class="small fw-light">{{n.description}}</div>
                                </div>

                                <div class="small text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat(n.dateCreate), new Date()) }}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="mdlDelAllNotif"
        tabindex="-1"
        aria-labelledby="delAllNotif"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>Please confirm if you would like to delete all notifications.</div>
                    <div>Once deleted, notifications cannot be undone or recovered.</div>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button type="button" class="btn btn-secondary" @click="delAllNotification" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Delete All
                    </button>
                    <button type="button" class="btn btn-outline-secondary me-2" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'

export default {
  name: 'Notification',
  props: [ 'showBox' ],
  emits: ['totalUnread'],
  components: { Popper },
  setup (props, { emit }) {

    const axios = inject('axios')
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const func = funcs

    const isSignOn = ref(false)

    const notifHeight = ref(0)
    const boxShow = ref(props.showBox)

    const arrNotif = ref([])
    const notifFr = ref(0)    //
    const notifTo = ref(10)    // Max SSO records
    const notifPage = ref(1)    // cur page
    const notifLastPage = ref(1)    // last page
    const totalNotif = ref(0)
    const totalUnreadNotif = ref(0)
    const notifFilter = ref('')   


    watch([notifFilter], () => {
        getNotification()
    })

    watch([totalUnreadNotif], () => {
        // console.info('watch', totalUnreadNotif.value)
        emit('totalUnread', totalUnreadNotif.value)
        
    })

    const delNotification = (id) => {
        
        const p = { 
            id: [ id ]
        }
        axios.put( '/report/notice/delete', p)
            .then((res) => {
                // console.info('- - del notif res', JSON.stringify(res))
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {

                    getTotalUnread()
                    getNotification()

                    emit('totalUnread', totalUnreadNotif.value)

                } else {
                    func.addLog('notification', 'delNotification', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }

                }

            })
            .catch((error) => {
                func.addLog('notification', 'delNotification - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })

    }

    const delAllNotification = () => {
        
        axios.delete( '/report/notice/all')
            .then((res) => {
                console.info('- - del all notif res', JSON.stringify(res))
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    
                    console.info('success del')
                   /*  alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "All notifications are deleted."
                    }) */

                    getTotalUnread()
                    getNotification()

                    console.info('success del unread', totalUnreadNotif.value)

                    emit('totalUnread', totalUnreadNotif.value)

                } else {
                    func.addLog('notification', 'delAllNotification', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }

                }

            })
            .catch((error) => {
                func.addLog('notification', 'delAllNotification - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })

    }

    const navigateNotif = async (opt) => {
        if (opt === 'prev') {
            notifPage.value = notifPage.value - 1
            notifFr.value = notifFr.value - notifTo.value

            getNotification()
        }

        if (opt === 'next') {
            notifPage.value = notifPage.value + 1
            notifFr.value = notifFr.value + notifTo.value

            getNotification()
        }
    }

    const readNotification = (id, isRead) => {
        if (isRead === false) {
            const p = { id: [ id ] }

            axios.put( '/report/notice/read', p
            ).then((res) => {

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    
                        getNotification()
                        getTotalUnread()

                    } else {
                        func.addLog('dashboardOverview', 'readNotification', res)

                        if (res && res.data !== null && res.data !== undefined) {

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                                
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'readNotification - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }
    }

    const getTotalUnread = () => {
        const p = {
            module: ['SIGNON'],
            orderBy: [
                {
                    field: 'dateCreate',
                    order: 'desc'
                }
            ],
            isRead: false,
            limit: notifFr.value + ", " + notifTo.value
        }

        // console.info('getNotification p ' + notifFr.value, JSON.stringify(p))
        axios.post( '/report/notice', p)
            .then((res) => {
                // console.info('notification', res.data.description.rowCount)
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    totalUnreadNotif.value = res.data.description.rowCount
                    emit('totalUnread', totalUnreadNotif.value)

                } else {
                    func.addLog('notification', 'getTotalUnread', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                            
                    }

                }

            })
            .catch((error) => {
                func.addLog('notification', 'getTotalUnread - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
    }

    const getNotification = () => {
        arrNotif.value = []
        const p = {
            module: ['SIGNON'],
            orderBy: [
                {
                    field: 'dateCreate',
                    order: 'desc'
                }
            ],
            limit: notifFr.value + ", " + notifTo.value
        }

        if (notifFilter.value === 'unread') {
            p.isRead = false
        }

        if (notifFilter.value === 'read') {
            p.isRead = true
        }

        // console.info('getNotification p ' + notifFr.value, JSON.stringify(p))
        axios.post( '/report/notice', p)
            .then((res) => {
                // console.info('getNotification rowCount', res.data.description.rowCount)
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    totalNotif.value = res.data.description.rowCount
                    arrNotif.value = res.data.data
                    notifLastPage.value = Math.ceil(res.data.description.rowCount/notifTo.value)

                } else {
                    func.addLog('notification', 'getNotification', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                            
                    }

                }

            })
            .catch((error) => {
                func.addLog('dashboardOverview', 'getNotification - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
    }

    onMounted(() => {
        // deduct header and footer navigationbar
        notifHeight.value = window.innerHeight - 86

        if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
            let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))

            if ( objLSEntity.entityId === '1') {
                isSignOn.value = true
            }
        }

        getTotalUnread()
        getNotification()


    })

    return {
        store, route, router, func, isSignOn, notifHeight, boxShow,
        arrNotif, notifFr, notifTo, notifPage, notifLastPage, totalNotif, totalUnreadNotif, notifFilter,
        readNotification, delNotification, navigateNotif, delAllNotification
    }
  }

}
</script>

<style>
.notificationFloat {
    /* z-index: 2000; */
    z-index: 1040;
    top: 49px;
    opacity: 0.9;
    background-color: var(--toolbar-bg);
    width: 100%;
}

.notificationInnerBox {
    overflow-y: auto;
    padding: 5px;
    /* border: 1px dashed orange; */
}

@media (min-width: 576px) {
    .notificationFloat {
        z-index: 2000;
        top: 49px;
        opacity: 0.9;
        width: 50%;
        background-color: var(--toolbar-bg);
    }
}
@media (min-width: 768px) {
    .notificationFloat {
        z-index: 2000;
        top: 49px;
        opacity: 0.9;
        width: 50%;
        background-color: var(--toolbar-bg);
    }
}
@media (min-width: 992px) {
    .notificationFloat {
        z-index: 2000;
        top: 49px;
        width: 500px; 
        opacity: 0.9;
        background-color: var(--toolbar-bg);
    }
}
@media (min-width: 1200px) {
    .notificationFloat {
        z-index: 2000;
        top: 49px;
        width: 500px; 
        opacity: 0.9;
        background-color: var(--toolbar-bg);
    }
}
@media (min-width: 1400px) {
    .notificationFloat {
        z-index: 2000;
        top: 49px;
        width: 500px; 
        opacity: 0.9;
        background-color: var(--toolbar-bg);
    }
}

</style>